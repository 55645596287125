@import "@vmf/components/src/assets/style/_index.scss";


















































































































































































.schedule-calendar {
    .el-calendar-day {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        overflow: hidden;
        height: initial;
        max-height: 160px;
        min-height: 85px;
        .calendar-item {
            flex: 1 1 1%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            overflow: hidden;
            > .label {
                flex: 0 0 auto;
            }
            > .content {
                flex: 1 1 1%;
                font-size: smaller;
                overflow-y: auto;
                @include beautify-scrollbar();
            }
        }
    }
}
